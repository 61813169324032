<template>
  <div class="breadcrumb">
    <v-breadcrumbs :items="items">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
  export default {
    name: 'Breadcrumb',

    props: {
      items: Array
    },

    data: () => ({
    }),
  }
</script>

<style lang="scss">
// drzewko dla widokow
.breadcrumb {
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
  ul {
    padding-bottom: 5px;
  }
}
</style>